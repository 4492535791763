import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { languageLable } from "utilities/helpers";
import { RAZORPAY_CREDENTIALS, RAZORPAY_ENV } from "utilities/constants";
import { Table, Spin, Button, notification } from "antd";
import API from "api/api";
import moment from "moment";

const PAYMENT_STATUS = {
  PAID: "Paid",
  PENDING: "Pending",
};

const api = new API();
export default function InvoiceTable() {
  const [loading, setLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const appState = useSelector((state) => state);
  const { selectedLanguageData } = appState?.app;
  const { appDefaultSetting, defaultAddress, userData } = appState?.auth;

  const fetchInvoice = useCallback(async () => {
    setLoading(true);

    if (appDefaultSetting?.CustomerCode) {
      try {
        const res = await api.post("GetCustomerOverdues", {
          data: {
            CustomerNumber: appDefaultSetting.CustomerCode,
            OpenItemDate: moment().format("DD.MM.YYYY"),
          },
        });
        if (res.Result?.Data?.length > 0) {
          res.Result?.Data?.forEach((item, index) => {
            item.key = index;
          });

          setInvoiceData(res.Result?.Data);
        }
        setLoading(false);
      } catch (e) {
        console.log("Due Invoice ~ fetchInvoice ~ e:", e);
        setLoading(false);
      }
    }
  }, [appDefaultSetting?.CustomerCode]);

  useEffect(() => {
    if (appDefaultSetting?.CustomerCode) {
      fetchInvoice();
    }
  }, [appDefaultSetting?.CustomerCode, fetchInvoice]);

    const confirmBalanceTransaction = (data, razorPayPaymentId) => {
        setLoading(true);
    if (data.GatewayOrderId && razorPayPaymentId) {
      try {
        api
          .get(
            `ConfimBalanceTransaction?PaymentOrderId=${data.GatewayOrderId}&TransactionId=${razorPayPaymentId}`
          )
          .then((response) => {
            if (response.StatusCode === 200 && response.Result) {
              notification.success({
                message: languageLable(
                  selectedLanguageData,
                  "Payment has been successfully."
                ),
              });
              setLoading(false);
                fetchInvoice();
            }
          });
      } catch (e) {
        notification.error({
          message: "Error",
          description: e,
        });
          setLoading(false);
          fetchInvoice();
      } finally {
          
      }
    }
  };

  const singlePaymentRedirect = async (record) => {
    const businessId =
      defaultAddress &&
      defaultAddress.BusinessCustomerAddressMapping &&
      defaultAddress.BusinessCustomerAddressMapping.BusinessId;

    if (record && businessId) {
      try {
        const singlePayment = await api.post(
          `ProceedInvoiceUsingGateway?businessId=${businessId}`,
          {
            data: record,
          }
        );
        if (singlePayment.Result) {
          razorPayRedirect(singlePayment.Result);
        }
      } catch (e) {
        console.log("singlePaymentRedirect ~ e:", e);
      }
    }
  };

  const multipleInvoiceRedirect = async () => {
    const businessId =
      defaultAddress &&
      defaultAddress.BusinessCustomerAddressMapping &&
      defaultAddress.BusinessCustomerAddressMapping.BusinessId;

    let records = [];
    invoiceData.forEach((item) => {
      if (selectedRowKeys.find((key) => item.key === key)) {
        records.push(item);
      }
    });

    if (records.length && businessId) {
      try {
        const singlePayment = await api.post(
          `ProceedMultiInvoices?businessId=${businessId}`,
          {
            data: records,
          }
        );
        if (singlePayment.Result) {
          razorPayRedirect(singlePayment.Result);
        }
      } catch (e) {
        console.log("singlePaymentRedirect ~ e:", e);
      }
    }
  };

  const razorPayRedirect = (data) => {
    if (data && data.GatewayOrderId) {
      let options = {
        key: RAZORPAY_CREDENTIALS[RAZORPAY_ENV].key,
        order_id: data.GatewayOrderId,
        name: RAZORPAY_CREDENTIALS.MERCHANT_NAME,
        handler: (response) => {
          if (response.razorpay_payment_id) {
            confirmBalanceTransaction(data, response.razorpay_payment_id);
          } else {
            notification.error({
              message: "Error",
              description: languageLable(
                selectedLanguageData,
                "Something Went wrong. Please try after sometime."
              ),
            });
          }
        },
        prefill: {
          name: userData && userData.name,
          email: userData && userData.email,
          contact: userData && userData.phonenumber,
        },
        theme: {
          color: "#2c5662",
        },
        modal: {
          ondismiss: function () {
            window.location.reload();
          },
        },
      };
      let rzp = new window.Razorpay(options);
      rzp.open();
    }
  };

  const tableColumn = [
    {
      title: languageLable(selectedLanguageData, "Order No."),
      dataIndex: "BioConnectOrderNo",
      key: "BioConnectOrderNo",
      render: (text, record) =>
        record.BioConnectOrderNo ? (
          <div>{record.BioConnectOrderNo}</div>
        ) : (
          <div>---</div>
        ),
    },
    {
      title: languageLable(selectedLanguageData, "Invoice No."),
      dataIndex: "Assignment",
      key: "Assignment",
    },
    {
      title: languageLable(selectedLanguageData, "Invoice Date"),
      dataIndex: "DocumentDate",
      key: "DocumentDate",
      render: (text, record) => (
        <div>{moment(record.DocumentDate).format("DD.MM.YYYY")}</div>
      ),
    },
    {
      title: languageLable(selectedLanguageData, "Due Date"),
      dataIndex: "DueDate",
      key: "DueDate",
      render: (text, record) => (
        <div>{moment(record.DueDate).format("DD.MM.YYYY")}</div>
      ),
    },
    {
      title: languageLable(selectedLanguageData, "Amount"),
      dataIndex: "Amount",
      key: "Amount",
    },
    {
      title: languageLable(selectedLanguageData, "Status"),
      dataIndex: "Status",
      key: "Status",
    },
    {
      title: languageLable(selectedLanguageData, "Payment ReferenceID"),
      dataIndex: "PaymentReferenceID",
      key: "PaymentReferenceID",
      render: (text, record) =>
        record.PaymentReferenceID ? (
          <div className="text-center">{record.PaymentReferenceID}</div>
        ) : (
          <div className="text-center">---</div>
        ),
    },
    {
      title: languageLable(selectedLanguageData, "Payment Mode"),
      dataIndex: "PaymentReferenceID",
      key: "PaymentReferenceID",
      render: (text, record) =>
        record.PaymentReferenceID ? (
          <div className="text-center">Online</div>
        ) : (
          <div className="text-center">---</div>
        ),
    },
    {
      title: languageLable(selectedLanguageData, "Pay"),
      dataIndex: "Pay",
      key: "Pay",
      render: (text, record) => {
        return record.Status === PAYMENT_STATUS.PENDING ? (
          <Button type="primary" onClick={() => singlePaymentRedirect(record)}>
            Pay
          </Button>
        ) : (
          "---"
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  return (
    <div className="due-invoice-container">
      <div className="pay-button-container">
        <Button
          type="primary"
          onClick={multipleInvoiceRedirect}
          disabled={!selectedRowKeys.length}
        >
          Pay
        </Button>
      </div>
      <Spin spinning={loading}>
        <Table
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
            getCheckboxProps: (record) => {
              return {
                disabled: record.Status === PAYMENT_STATUS.PAID,
                Status: record.Status,
              };
            },
          }}
          columns={tableColumn}
          dataSource={invoiceData}
        />
      </Spin>
    </div>
  );
}
