import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import RadialGaugeBar from "./RadialGaugeBar";
import AccountLinks from "../AccountLinks";
import { languageLable } from "utilities/helpers";
import moment from "moment/moment";

class UserInformation extends Component {
  render() {
    const { selectedLanguageData, loyalProgramChartData } = this.props.app;

    const getPercentage = (target, fullTarget) => {
      return parseInt((target * 100) / fullTarget);
    };

    const target1Percentage = getPercentage(
      loyalProgramChartData.GlodermQuantity,
      loyalProgramChartData.TargetQuantity1
    );
    const target2Percentage = getPercentage(
      loyalProgramChartData.GlodermQuantity,
      loyalProgramChartData.TargetQuantity2
    );

    return (
      <section className="ps-my-account ps-page--account">
        <div className="ps-container">
          <div className="row">
            <AccountLinks page="loyalty-program" />
            <div className="col-lg-8">
              <div className="ps-form--account-setting ps-form--user-info">
                <div className="ps-form__header">
                  <h3>
                    {languageLable(selectedLanguageData, "Loyalty Program")}
                  </h3>
                </div>
                <div className="ps-form__content">
                  <div className="loyal-program-wrapper">
                    {Object.keys(loyalProgramChartData).length > 0 && (
                      <RadialGaugeBar
                        qty={loyalProgramChartData.GlodermQuantity}
                        radius={250}
                        strokeValueColor="#64BEAC"
                        strokeBackgroundColor="#B3B3B3"
                        angleOffset={10}
                        strokeWidth={15}
                        min={0}
                        max={loyalProgramChartData.TargetQuantity2}
                        divisions={10}
                        ticksPerDivision={5}
                        target1={loyalProgramChartData.TargetQuantity1}
                        target2={loyalProgramChartData.TargetQuantity2}
                        target1Percentage={target1Percentage}
                        target2Percentage={target2Percentage}
                        target1Color="#57C1AD"
                        target2Color="#264B5B"
                        labelText={`Protocol end date: ${moment(
                          loyalProgramChartData.LoyaltyProtocolDate
                        ).format("DD.MM.YY")}`}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(withRouter(UserInformation));
