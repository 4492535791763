import React from "react";

const RadialGaugeBar = ({
  qty,
  radius,
  strokeValueColor,
  strokeBackgroundColor,
  strokeWidth = 8,
  min = 0,
  max,
  divisions = 10,
  ticksPerDivision = 5,
  target1,
  target2,
  target1Color,
  target2Color,
  labelText,
  target1Percentage,
  target2Percentage,
}) => {
  // Cap qty to max value
  const cappedQty = Math.min(qty, max);

  const ticks = Array.from(
    { length: divisions * ticksPerDivision + 1 },
    (_, i) => {
      const angle = 180 + (180 / (divisions * ticksPerDivision)) * i;
      const isMajorTick = i % ticksPerDivision === 0;
      return {
        angle,
        isMajorTick,
        value: min + (i / (divisions * ticksPerDivision)) * (max - min),
      };
    }
  );

  // Use cappedQty for progressAngle calculation
  const progressAngle = (cappedQty / (max - min)) * Math.PI;

  const getTargetCoordinates = (value) => {
    const angle = ((value - min) / (max - min)) * 180 + 180;
    return {
      x: Math.cos((angle * Math.PI) / 180) * (radius - strokeWidth / 2) + 5,
      y: Math.sin((angle * Math.PI) / 180) * (radius - strokeWidth / 2) - 5,
    };
  };

  const target1Coords = getTargetCoordinates(target1);
  const target2Coords = getTargetCoordinates(target2);

  return (
    <div className="chart-container">
      <svg
        width={radius * 2 + 50} // Adjusted width
        height={radius + 120} // Adjusted height
        viewBox={`-20 -25 ${radius * 2 + 40} ${radius + 140}`} // Tightened viewBox
      >
        <g transform={`translate(${radius}, ${radius})`}>
          {/* Background Arc */}
          <path
            d={`M ${-radius} 0 A ${radius} ${radius} 0 0 1 ${radius} 0`}
            stroke={strokeBackgroundColor}
            strokeWidth={strokeWidth}
            fill="none"
          />

          {/* Progress Arc */}
          <path
            d={`M ${-radius} 0 A ${radius} ${radius} 0 ${
              progressAngle > Math.PI ? 1 : 0
            } 1 ${Math.cos(progressAngle - Math.PI) * radius} ${
              Math.sin(progressAngle - Math.PI) * radius
            }`}
            stroke={strokeValueColor}
            strokeWidth={strokeWidth}
            fill="none"
            style={{ transition: "stroke-dasharray 1s ease-out" }}
          />

          {/* Ticks and Labels */}
          {ticks.map(({ angle, isMajorTick, value }) => {
            const length = isMajorTick ? 15 : 10;
            const x1 = Math.cos((angle * Math.PI) / 180) * (radius - length);
            const y1 = Math.sin((angle * Math.PI) / 180) * (radius - length);
            const x2 = Math.cos((angle * Math.PI) / 180) * radius;
            const y2 = Math.sin((angle * Math.PI) / 180) * radius;

            const labelX = Math.cos((angle * Math.PI) / 180) * (radius + 20);
            const labelY = Math.sin((angle * Math.PI) / 180) * (radius + 20);

            return (
              <g key={angle}>
                {/* Tick Line */}
                <line
                  x1={x1}
                  y1={y1}
                  x2={x2}
                  y2={y2}
                  stroke="grey"
                  strokeWidth={isMajorTick ? 2 : 1}
                />
                {/* Tick Label */}
                {isMajorTick && (
                  <text
                    x={labelX}
                    y={labelY}
                    style={{
                      fontSize: 12,
                      fill: "black",
                    }}
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    {Math.round(value)}
                  </text>
                )}
              </g>
            );
          })}

          {/* Target 1 Marker */}
          <circle
            cx={target1Coords.x}
            cy={target1Coords.y}
            r={15}
            fill={target1Color}
          />
          <text
            x={target1Coords.x}
            y={target1Coords.y + 4}
            textAnchor="middle"
            style={{
              fontSize: 10,
              fill: "white",
              fontWeight: "bold",
              padding: "2px",
            }}
          >
            {target1}
          </text>

          {/* Target 2 Marker */}
          <circle
            cx={target2Coords.x}
            cy={target2Coords.y}
            r={15}
            fill={target2Color}
          />
          <text
            x={target2Coords.x}
            y={target2Coords.y + 4}
            textAnchor="middle"
            style={{
              fontSize: 10,
              fill: "white",
              fontWeight: "bold",
              padding: "2px",
            }}
          >
            {target2}
          </text>
        </g>
      </svg>
      {/* Target Descriptions */}
      <div className="target-descriptions">
        <div className="target">
          <div className="target-description">
            <div className="target-item">
              <p style={{ color: target2Color }}>Target: {target1} qty</p>
            </div>
            <div
              className="target-qty"
              style={{ color: target1Color, fontSize: "16px" }}
            >
              {qty} qty
            </div>
            <div className="target-item">
              <p style={{ fontSize: "42px", color: "#264B5B", margin: 0 }}>
                {target1Percentage ?? "0"}%
              </p>
            </div>
          </div>
          <div className="vertical-line"></div>
          <div className="target-description">
            <div className="target-item">
              <p style={{ color: target2Color }}>Target: {target2} qty</p>
            </div>
            <div
              className="target-qty"
              style={{ color: target1Color, fontSize: "16px" }}
            >
              {qty} qty
            </div>
            <div className="target-item">
              <p style={{ fontSize: "42px", color: "#264B5B", margin: 0 }}>
                {target2Percentage ?? "0"}%
              </p>
            </div>
          </div>
        </div>
        {/* Protocol End Date */}
        <p style={{ marginTop: 10, fontSize: 12, color: "grey" }}>
          {labelText}
        </p>
      </div>
    </div>
  );
};

export default RadialGaugeBar;
