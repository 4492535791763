export const actionTypes = {
  SWITCH_DEMO_PANEL: "SWITCH_DEMO_PANEL",
  SWITCH_DEMO_PANEL_SUCCESS: "SWITCH_DEMO_PANEL_SUCCESS",
  FETCH_CATEGORIES: "FETCH_CATEGORIES",
  FETCH_CART_DATA: "FETCH_CART_DATA",
  FETCH_LANGUAGE_LIST: "FETCH_LANGUAGE_LIST",
  FETCH_SELECTED_LANGUAGE_DATA: "FETCH_SELECTED_LANGUAGE_DATA",
  FETCH_LANGUAGE_LOCAL: "FETCH_LANGUAGE_LOCAL",
  UPDATE_USER_LANGUAGE: "UPDATE_USER_LANGUAGE",
  LOYALTY_GLODERM_MODAL: "LOYALTY_GLODERM_MODAL",
  GOLDER_LOYALTY_PROGRAM: "GOLDER_LOYALTY_PROGRAM",
  FETCH_CURRENCY: "FETCH_CURRENCY",
};

export function switchDemoPanel(payload) {
  return { type: actionTypes.SWITCH_DEMO_PANEL, payload };
}

export function switchDemoPanelSuccess(payload) {
  return { type: actionTypes.SWITCH_DEMO_PANEL_SUCCESS, payload };
}
