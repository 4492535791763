import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment/moment";
import { Modal } from "antd";
import API from "./../../../api/api";

var api;
const restModal = {
  isModalVisible: false,
  modalMessage: "",
  whichTarget: 0, // EUM ==>> Target1 => 1, Target2 => 2
};

class LoyaltyProgramModal extends Component {
  constructor(props) {
    super(props);
    api = new API();
    this.state = {
      ...restModal,
    };
    this.toggleModal.bind(this);
  }

  componentDidMount = () => {
    const { loyalProgramChartData } = this.props.app;
    if (
      !loyalProgramChartData.Target1Achieved &&
      loyalProgramChartData.GlodermQuantity >=
        loyalProgramChartData.TargetQuantity1
    ) {
      this.setState({
        isModalVisible: true,
        modalMessage: `Congratulations, you have reached the contract requirement of ${
          loyalProgramChartData.TargetQuantity1
        } units. If you reach ${
          loyalProgramChartData.TargetQuantity2
        } units by the contract end date of ${moment(
          loyalProgramChartData.LoyaltyProtocolDate
        ).format("DD.MM.YY")}, you will be entitled to 10% FOC.`,
        whichTarget: 1,
      });
    } else if (
      !loyalProgramChartData.Target2Achieved &&
      loyalProgramChartData.GlodermQuantity >=
        loyalProgramChartData.TargetQuantity2
    ) {
      this.setState({
        isModalVisible: true,
        modalMessage: `Congratulations, you have reached the required ${loyalProgramChartData.TargetQuantity2} units for 10% FOC`,
        whichTarget: 2,
      });
    }
  };

  toggleModal = () => {
    //if (this.state.whichTarget) {
    //  const { whichTarget } = this.state;
    //  let target1 = false;
    //  let target2 = false;
    //  if (whichTarget === 1) {
    //    target1 = true;
    //  } else if (whichTarget === 2) {
    //    target1 = true;
    //    target2 = true;
    //  }

    //  api
    //    .get(`GlodermTargetAchieved?target1=${target1}&target2=${target2}`)
    //    .then((res) => {
    //      if (res.StatusCode === 200 && res.Result) {
    //        const businessId =
    //          this.props.auth &&
    //          this.props.auth.defaultAddress &&
    //          this.props.auth.defaultAddress.BusinessCustomerAddressMapping &&
    //          this.props.auth.defaultAddress.BusinessCustomerAddressMapping
    //            .BusinessId;

    //        if (businessId) {
    //          api.get(`GetGlodermData?businessId=${businessId}`).then((res) => {
    //            if (res.StatusCode === 200 && res.Result) {
    //              this.props.dispatch({
    //                type: "GOLDER_LOYALTY_PROGRAM",
    //                loyalProgramChartData: res.Result,
    //              });
    //            }
    //          });
    //        }
    //      }
    //    });

    //  this.setState({ ...restModal });
    //}
  };

  render() {
    return (
      <Modal
        title="Congratulations"
        visible={this.state.isModalVisible}
        onCancel={this.toggleModal}
        footer={null}
      >
        <p>{this.state.modalMessage}</p>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(withRouter(LoyaltyProgramModal));
