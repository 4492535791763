import React, { useState } from "react";
import { Collapse, Modal } from "antd";
import { IMAGE_BASE_URL, NoImg } from "utilities/constants";
import { useSelector } from "react-redux";

const { Panel } = Collapse;

const ProductAccordion = ({ data, handleExchangeRedirect }) => {
  const [isConformationModal, setIsConformationModal] = useState(false);
  const [isReplaceLimitReachOpen, setIsReplaceLimitReachOpen] = useState(false);
  const [selectedItem, setSetSelectedItem] = useState(null);

  const cartData = useSelector((state) => state.app.cartData);
  const businessSetting = useSelector((state) => state.app.currencySetting);

  // Group the data by Product.ProductName
  const groupedData = data.reduce((acc, item) => {
    const productName = item.Product.ProductName;
    if (!acc[productName]) {
      acc[productName] = [];
    }
    acc[productName].push(item);
    return acc;
  }, {});

  const openConformationModal = (productData) => {
    if (
      cartData == null ||
      cartData.CartItemViewModels == null ||
      cartData.CartItemViewModels.length <= 0
    ) {
      handleExchangeRedirect(productData);
    } else if (cartData && !cartData.CanAddMoreItem) {
      // Replace order limit has been reached
      setIsReplaceLimitReachOpen(true);
    } else {
      setIsConformationModal(true);
      setSetSelectedItem(productData);
    }
  };

  const confirmModalAction = () => {
    resetConformationModal();
    handleExchangeRedirect(selectedItem);
  };

  const resetConformationModal = () => {
    setIsConformationModal(false);
    setSetSelectedItem(null);
  };

  const toggleReplaceLimitReachModal = () => {
    setIsReplaceLimitReachOpen(!isReplaceLimitReachOpen);
  };

  // Convert groupedData to searchResults
  const searchResults = Object.keys(groupedData).map((productName) => ({
    productName,
    items: groupedData[productName],
  }));

  return (
    <>
      <Collapse defaultActiveKey={[`product-group-0`]}>
        {searchResults.map((group, index) => (
          <Panel header={group.productName} key={`product-group-${index}`}>
            {group.items.map((item, idx) => (
              <div
                className="library-product-container"
                key={`library-order-${idx}`}
              >
                <div className="library-product">
                  <div className="image-container">
                    {item.Product?.PrimaryMediaId ? (
                      <img
                        src={`${IMAGE_BASE_URL}/Media/GetMediaById?id=${item.Product.PrimaryMediaId}`}
                        alt={item.Product?.ProductName}
                      />
                    ) : (
                      <img src={NoImg} alt={item.Product?.ProductName} />
                    )}
                  </div>
                  <div className="product-details">
                    <div className="title">{item.Product?.ProductName}</div>
                    {item.Product?.Attributes.map((attr) => (
                      <div className="description" key={attr.id}>
                        {attr.attributeName}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="action-container">
                  <button
                    className={item.AddedInCart ? "ps-btn disabled" : "ps-btn"}
                    onClick={() => openConformationModal(item)}
                    disabled={item.AddedInCart}
                  >
                    Replace
                  </button>
                  <div className="sr-number">SR No : {item.SerialNumber}</div>
                  <div className="sr-number">
                    Invoice No : {item.InvoiceNumber}
                  </div>
                </div>
              </div>
            ))}
          </Panel>
        ))}
      </Collapse>
      {isConformationModal && (
        <Modal
          title="Replace Order"
          visible={isConformationModal}
          onCancel={resetConformationModal}
          footer={null}
        >
          <div className="replace-order-modal-wrapper">
            <p>Do you want to add one more lens for the replacement?</p>
            <div className="action-container">
              <button className={"ps-btn"} onClick={resetConformationModal}>
                No
              </button>
              <button className={"ps-btn"} onClick={confirmModalAction}>
                Yes
              </button>
            </div>
          </div>
        </Modal>
      )}

      {isReplaceLimitReachOpen && (
        <Modal
          title="Replace Order"
          visible={isReplaceLimitReachOpen}
          onCancel={toggleReplaceLimitReachModal}
          footer={null}
        >
          <div className="replace-order-modal-wrapper">
            <p>
              You have reached the maximum limit of
              <strong> {businessSetting.ReplaceOrderCount} </strong>invoices for
              a single exchange request. Please proceed with the current request
              or remove some invoices to continue.
            </p>
            <div className="action-container">
              <button
                className={"ps-btn"}
                onClick={toggleReplaceLimitReachModal}
              >
                Ok
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ProductAccordion;
