import React, { useCallback, useEffect, useState } from "react";
import { Input } from "antd";
import { useHistory } from "react-router-dom";
import AccountLinks from "../AccountLinks";
import API from "../../../../api/api";
import { useSelector } from "react-redux";
import { languageLable } from "utilities/helpers";
import { PRODUCT_ACTION } from "utilities/constants";
import ProductAccordion from "./ProductAccordion";

const api = new API();

export default function ExchangeOrder() {
  const { app, auth } = useSelector((state) => state);
  const history = useHistory();
  const { selectedLanguageData } = app;
  const [libraryOrders, setLibraryOrders] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const locationId =
    auth &&
    auth.defaultAddress &&
    auth.defaultAddress.BusinessCustomerAddressMapping &&
    auth.defaultAddress.BusinessCustomerAddressMapping.LocationId;

  const fetchLibraryOrder = useCallback(async () => {
    try {
      const libraryData = await api.get(
        `GetReplaceOrders?locationId=${locationId}`
      );

      if (libraryData?.Result?.length > 0) {
        setLibraryOrders(libraryData.Result);
        setSearchResults(libraryData.Result);
      }
    } catch (error) {
      console.error("fetchLibraryOrder -> error", error);
    }
  }, [locationId]);

  useEffect(() => {
    fetchLibraryOrder();
  }, [fetchLibraryOrder]);

  const handleOnChange = (e) => {
    const search = e.target.value.toLowerCase();
    const searchMatch = [];
    libraryOrders.forEach((item) => {
      if (
        item.Product?.ProductName.toLowerCase().includes(search) ||
        item.SerialNumber.toLowerCase().includes(search)
      ) {
        searchMatch.push(item);
      }
    });

    setSearchResults(searchMatch);
  };

  const handleExchangeRedirect = (product) => {
    if (product.ProductId) {
      history.push(
        `/product-details/${product?.Product?.GroupId}/Grouped?action=${PRODUCT_ACTION.EXCHANGE}&serialno=${product.SerialNumber}&InvoiceNumber=${product.InvoiceNumber}`
      );
    }
  };

  return (
    <section className="ps-my-account ps-page--account">
      <div className="ps-container">
        <div className="row">
          <AccountLinks page="exchange-order" />
          <div className="col-lg-8">
            <div className="ps-page__content">
              <div className="ps-section--account-setting">
                <div class="container-fluid ">
                  <div className="ps-section__header">
                    <h3>
                      {languageLable(selectedLanguageData, "Exchange Order")}
                    </h3>
                  </div>
                  <div className="ps-section__content">
                    <div className="form-group">
                      <Input
                        placeholder="Search"
                        className="form-control"
                        onChange={handleOnChange}
                        allowClear={true}
                      />
                    </div>
                    <ProductAccordion
                      data={searchResults}
                      handleExchangeRedirect={handleExchangeRedirect}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
