/* eslint-disable unicode-bom */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Table, notification, Select } from "antd";
import { connect } from "react-redux";
import ModuleProductDetailDescription from "components/elements/detail/modules/elements/ModuleProductDetailDescription";
import { languageLable, findParameterFromUrl } from "utilities/helpers";
import { PRODUCT_ACTION } from "utilities/constants";
import API from "api/api";
import swal from "sweetalert";

/**
 * Grouped Product
 */
const { Option } = Select;
var api;
class InformationDefault extends Component {
  constructor(props) {
    super(props);
    api = new API();
    this.state = {
      quantity: 0,
      selectedVariant: null,
      price: null,
      productAttributeValue: null, // SET THE PRODUCT DATA INTO STATE FOR MANAGING THE ATTRIBUTE
      loyaltypoint: 0,
      productAction: "Add +",
      serialNumberS: "",
      // Add to cart by default in case of action present in URL then it should be exchange flow
    };
  }

  componentDidMount = () => {
    this.setState({
      productAttributeValue: this.props.productData,
    });
    this.handleLoyaltyProductPoint();

    const action = findParameterFromUrl(window.location, "action");
    if (action === PRODUCT_ACTION.EXCHANGE) {
      this.setState({
        productAction: PRODUCT_ACTION.EXCHANGE,
      });
    }
  };

  handleAddItemToCart = async (row) => {
    const { selectedLanguageData } = this.props.app;
    const { productData } = this.props.productData;
    const isAuthorized = Boolean(productData?.ProductListings[0]?.IsAuthorised);

    let consignmentType = null;
    if (isAuthorized) {
      const libraryRefilling = localStorage.getItem("LibraryRefilling");
      const url = window.location.pathname.split("/");
      const groupId = url[2];

      if (libraryRefilling) {
        this.props.history.push(`/account/library-order/${groupId}`);
      } else {
        const alertActions = await swal({
          title: "Do you want to Order Library Refill?",
          icon: "info",
          dangerMode: true,
          showCancelButton: true,
          closeOnConfirm: true,
          showLoaderOnConfirm: true,
          buttons: {
            cancel: "No",
            yes: {
              text: "Yes",
            },
          },
        });

        consignmentType = "none";
        if (alertActions === "yes") {
          consignmentType = "FillUp";
        }
      }
    }

    if (this.state.productAction === PRODUCT_ACTION.EXCHANGE) {
      // IN CASE OF REPLACE ORDER
      consignmentType = PRODUCT_ACTION.EXCHANGE;
    }

    const businessId =
      this.props.auth &&
      this.props.auth.defaultAddress &&
      this.props.auth.defaultAddress.BusinessCustomerAddressMapping &&
      this.props.auth.defaultAddress.BusinessCustomerAddressMapping.BusinessId;

    const locationId =
      this.props.auth &&
      this.props.auth.defaultAddress &&
      this.props.auth.defaultAddress.BusinessCustomerAddressMapping &&
      this.props.auth.defaultAddress.BusinessCustomerAddressMapping.LocationId;

    // Add item to cart
    let addToCart = {
      productId: row.ProductId,
      businessId: businessId,
      businessLocationId: locationId,
      listingId: row.ProductListingId,
      quantity: row.PackingSize,
      amount: row.Price,
      offerAmount: row.Price,
      offerId: "",
      serialNumber: findParameterFromUrl(window.location, "serialno"),
      InvoiceNumber: findParameterFromUrl(window.location, "InvoiceNumber"),
    };

    let apiUrl = "AddItemToCart";
    if (consignmentType) {
      addToCart["consignmentType"] = consignmentType;
      apiUrl = `AddItemToCart?ConsignmentType=${consignmentType}`;
    }

    api
      .post(apiUrl, {
        data: addToCart,
      })
      .then((response) => {
        if (response.StatusCode === 200 && response.Result) {
          notification.success({
            message: "Success",
            description: languageLable(
              selectedLanguageData,
              "Item has been successfully added to cart."
            ),
          });

          this.props.dispatch({
            type: "FETCH_CART_DATA",
            cartData: response.Result,
          });
        }
      });
  };

  handleIncreaseItemQty = (row, itemId, quantity, packingSize) => {
    if (row && itemId) {
      if (quantity > 0) {
        if (this.state.productAction !== PRODUCT_ACTION.EXCHANGE) {
          // EXCHANGE PRODUCE WILL NOT HAVE THE INCREASE AND DECREASE OPTION
          // Update
          api
            .post(`QuantityUpdate?itemId=${itemId}&quantity=${1}`)
            .then((response) => {
              if (response.StatusCode === 200 && response.Result) {
                this.props.dispatch({
                  type: "FETCH_CART_DATA",
                  cartData: response.Result,
                });
              }
            });
        }
      } else {
        // Delete
        api.delete(`DeleteItem?itemId=${itemId}`).then((response) => {
          if (response.StatusCode === 200 && response.Result) {
            this.props.dispatch({
              type: "FETCH_CART_DATA",
              cartData: response.Result,
            });
          }
        });
      }
    }
  };

  handleDecreaseItemQty = (row, itemId, quantity, packingSize) => {
    if (row && itemId) {
      if (quantity > packingSize) {
        if (this.state.productAction !== PRODUCT_ACTION.EXCHANGE) {
          // EXCHANGE PRODUCE WILL NOT HAVE THE INCREASE AND DECREASE OPTION
          // Update
          api
            .post(`QuantityUpdate?itemId=${itemId}&quantity=${-1}`)
            .then((response) => {
              if (response.StatusCode === 200 && response.Result) {
                this.props.dispatch({
                  type: "FETCH_CART_DATA",
                  cartData: response.Result,
                });
              }
            });
        }
      } else {
        // Delete
        api.delete(`DeleteItem?itemId=${itemId}`).then((response) => {
          if (response.StatusCode === 200 && response.Result) {
            this.props.dispatch({
              type: "FETCH_CART_DATA",
              cartData: response.Result,
            });
          }
        });
      }
    }
  };

  onAttributeSelectionChange = (id, name) => {
    this.props.onAttributeSelectionChange(id, name);
  };

  prepareAttributeOption = () => {
    const productAttributeValue = this.props.allProductData;
    return (
      <div className="attribute-container">
        {productAttributeValue.Attributes &&
          productAttributeValue.Attributes.map((item, index) => {
            const options =
              productAttributeValue.AttributeValues &&
              productAttributeValue.AttributeValues.filter(
                (value) => value.attributeid === item.Id
              );
            return (
              <div className="attribute">
                <div className="title-container">
                  <strong>{item.Name}</strong>
                </div>
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder={`Select ${item.Name}`}
                  optionFilterProp="children"
                  onChange={(id) =>
                    this.onAttributeSelectionChange(id, item.Name)
                  }
                >
                  <Option value={null}>{`Please select`}</Option>
                  {options &&
                    options.map((option) => (
                      <Option value={option.id}>{option.text}</Option>
                    ))}
                </Select>
              </div>
            );
          })}
      </div>
    );
  };

  handleLoyaltyProductPoint = async () => {
    if (localStorage.getItem("IsLoyaltyEnable") === "true") {
      setTimeout(() => {
        //let gsku = JSON.parse(localStorage.getItem('@skugroup'));
        let gsku = localStorage.getItem("@skugroup");

        var requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        //gsku.map(item =>{
        fetch(
          `https://ecom.loyalty.biotechhealthcare.com/api/GetLoyaltyPoint?productSku=${gsku}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            this.setState({
              loyaltypoint: result.data.points,
            });
          })
          .catch((error) => console.error("error", error));
        //})
      }, 1000);
    }
  };

  render() {
    const { productData } = this.props;
    const { selectedLanguageData } = this.props.app;
    const currencySetting = localStorage.getItem("currency");
    const currencyDescription = localStorage.getItem("currencydescription");
    // const taxNameString = localStorage.getItem("taxNameString");
    const canOrder =
      this.props.auth &&
      this.props.auth.userData &&
      this.props.auth.userData.can_order === "true"
        ? true
        : false;

    const columns = [];
    if (productData && productData.Attributes) {
      productData.Attributes.forEach((item) => {
        columns.push({
          title: item.Name,
          dataIndex: item.Name,
          key: item.Id,
        });
      });
    }

    if (canOrder) {
      // Stock
      // columns.push({
      //   title: languageLable(selectedLanguageData, "Available Stock"),
      //   dataIndex: "Availability",
      //   key: "Available Stock",
      // });

      // Price
      columns.push({
        title: languageLable(selectedLanguageData, "Price"),
        dataIndex: "",
        key: "Price",
        render: (row) => {
          return (
            <div className="price">
              {languageLable(selectedLanguageData, "")}
              {currencySetting ? currencySetting : "₹ "}
              {row.Price}
              {row.DiscountPercent > 0 && <s>{row.MaxRetailPrice}</s>}
            </div>
          );
        },
      });

      // Action
      columns.push({
        title: languageLable(selectedLanguageData, "Action"),
        dataIndex: "",
        key: "Cart",
        render: (row) => this.getCartButton(canOrder, row),
      });
    }

    const attributeData = [];
    if (productData.Attributes && productData.ProductDetailViews) {
      productData.ProductDetailViews.forEach((detailsAttribute) => {
        // if ProductListing is available than show the record
        if (
          detailsAttribute !== null &&
          detailsAttribute.ProductListings !== null &&
          detailsAttribute.ProductListings.length > 0
        ) {
          let attributes = {};
          detailsAttribute.Attributes.forEach((item) => {
            attributes[item.attribute] = item.text;
          });

          detailsAttribute.ProductListings.forEach((ProductListing) => {
            attributes["PackingSize"] =
              ProductListing && ProductListing.PackingSize;
            if (ProductListing && canOrder) {
              attributes["ProductId"] = ProductListing.ProductId;
              attributes["key"] = ProductListing.ProductId;
              if (this.props.auth && this.props.auth.isLoggedIn) {
                // Product Not availble set notify button
                if (ProductListing.Availability !== "Enquiry") {
                  attributes["Availability"] =
                    ProductListing.Availability === "Unlimited"
                      ? ProductListing.Availability
                      : ProductListing.Quantity > 0
                      ? ProductListing.Quantity
                      : 0;

                  const taxValue =
                    ProductListing.TaxValue && ProductListing.TaxValue;
                  const offerPrice =
                    ProductListing.OfferPrice && ProductListing.OfferPrice;
                  const maxRetailPrice =
                    ProductListing.MaxRetailPrice &&
                    ProductListing.MaxRetailPrice;

                  attributes["Price"] = (offerPrice + taxValue).toFixed(2);

                  attributes["MaxRetailPrice"] = (
                    maxRetailPrice + taxValue
                  ).toFixed(2);

                  attributes["DiscountPercent"] =
                    ProductListing.DiscountPercent &&
                    ProductListing.DiscountPercent.toFixed(2);
                  attributes["ProductListingId"] = ProductListing.Id;
                } else {
                  attributes["Availability"] = "Enquiry";
                  attributes["Price"] = "---";
                  attributes["ProductListingId"] = ProductListing.Id;
                }
              }
            } else {
              attributes["ProductId"] = "---";
              attributes["key"] = Math.random();
              attributes["Availability"] = "---";
              attributes["Price"] = "---";
            }
          });

          attributeData.push(attributes);
        }
      });
    }

    const productAttributeValue = this.props.productData;

    const ProductDetailViews = productData.ProductDetailViews;
    const isLoyaltyEnable = localStorage.getItem("IsLoyaltyEnable");

    var ProductDetailViewsFilterData = [];
    var CustomFieldData = [];
    var customFieldDataUSD = "";
    var customFieldDataUSDValue = "";

    if (ProductDetailViews !== undefined) {
      ProductDetailViewsFilterData = ProductDetailViews.filter((i) => {
        return i !== null && i.ProductListings !== null
          ? i.ProductListings.length > 0
          : 0;
      });

      if (ProductDetailViewsFilterData.length > 0) {
        if (
          ProductDetailViewsFilterData[0].ProductListings[0].CustomFieldData !==
          undefined
        ) {
          CustomFieldData =
            ProductDetailViewsFilterData[0].ProductListings[0].CustomFieldData;
          var datacustom = JSON.parse(CustomFieldData);

          var data = [];
          var data1 = [];

          function isNumber(n) {
            return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
          }

          data = datacustom.filter((item) => {
            return isNumber(item.Value) === false ? item : "";
          });
          data1 = datacustom.filter((item) => {
            return isNumber(item.Value) === true ? item : "";
          });

          customFieldDataUSD = data.count > 0 ? data[0].Value : "";
          customFieldDataUSDValue =
            data.count > 0 ? parseFloat(data1[0].Value).toFixed(2) : "";
        }
      }
    }

    return (
      <div className="ps-product__info">
        {/* <div class="row" > */}
        <h1>{productData.ProductName}</h1>
        <div className="col-lg-7">
          <ModuleProductDetailDescription
            product={productData}
            selectedLanguageData={selectedLanguageData}
          />
        </div>

        {currencyDescription !== "" ? (
          <>
            <div
              className="col-lg-7 new-line"
              style={{ marginTop: -15, marginBottom: 20 }}
            >
              {currencyDescription}
            </div>
          </>
        ) : (
          <></>
        )}

        {/* {taxNameString !== "" &&
        taxNameString !== "undefined" &&
        productData &&
        productData.ProductDetailViews &&
        productData.ProductDetailViews.length > 0 &&
        productData.ProductDetailViews[0].ProductListings &&
        productData.ProductDetailViews[0].ProductListings.length > 0 ? (
          <>
            <div className="col-lg-7" style={{ marginTop: -15 }}>
              {languageLable(selectedLanguageData, taxNameString)}{" "}
              {currencySetting ? currencySetting : "₹"}
              {productData.ProductDetailViews[0].ProductListings[0].TaxValue.toFixed(
                2
              )}
            </div>
          </>
        ) : (
          <></>
        )}*/}

        {customFieldDataUSDValue !== "" && customFieldDataUSD !== "" ? (
          <>
            <div className="col-lg-7" style={{ marginTop: -15 }}>
              1 {customFieldDataUSD} = {customFieldDataUSDValue}
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="col-lg-7">
          {isLoyaltyEnable === "true" && this.state.loyaltypoint > 0 ? (
            <div style={{ marginTop: 5 }}>
              <button
                style={{
                  background: "#2C5662",
                  color: "#fff",
                  padding: "5px 10px 5px",
                  border: "0px",
                  borderRadius: 8,
                }}
              >
                {languageLable(selectedLanguageData, this.state.loyaltypoint)}{" "}
                {languageLable(selectedLanguageData, "Loyalty Points")}
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>

        {/* </div> */}

        <div className="ps-product__desc" style={{ marginTop: 5 }} />

        <div className="ps-product-attibute">
          {productAttributeValue &&
            productAttributeValue.Attributes &&
            productAttributeValue.Attributes.length > 1 && (
              <div className="attribute-wrapper">
                {this.prepareAttributeOption()}
                <div className="search-container">
                  <button
                    className="ps-btn"
                    onClick={this.props.getSelectedAttributeList}
                  >
                    {languageLable(selectedLanguageData, "Search")}
                  </button>
                </div>
              </div>
            )}

          <Table
            dataSource={attributeData}
            columns={columns}
            //size="middle"
            pagination={
              attributeData && attributeData.length > 10 ? true : false
            }
          />
        </div>
      </div>
    );
  }

  getCartButton = (canOrder, row) => {
    if (this.props.auth && this.props.auth.isLoggedIn && canOrder) {
      const { cartData } = this.props.app;

      let quantity = 0;
      let itemId = null;
      let packingSize = null;

      if (cartData) {
        cartData.CartItemViewModels &&
          cartData.CartItemViewModels.forEach((item) => {
            if (item.Id === row.ProductId) {
              quantity = item.Quantity;
              itemId = item.ItemId;
              packingSize = item.PackingSize;
            }
          });
      }
      const { selectedLanguageData } = this.props.app;

      return (
        <React.Fragment>
          <div className="ps-product__shopping ps-product__shopping-table">
            {row.Availability === "Enquiry" ? (
              <figure>
                <div className="form-group--number pointer">
                  <input
                    onClick={() => this.addProductEnquiry(row)}
                    className="form-control pointer"
                    type="text"
                    placeholder={languageLable(
                      selectedLanguageData,
                      "Notify Me"
                    )}
                    readOnly
                  />
                </div>
              </figure>
            ) : (
              <figure>
                {quantity > 0 ? (
                  <React.Fragment>
                    <div className="form-group--number">
                      <button
                        className="up"
                        onClick={() =>
                          this.handleIncreaseItemQty(
                            row,
                            itemId,
                            quantity,
                            packingSize
                          )
                        }
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                      <button
                        className="down"
                        onClick={() =>
                          this.handleDecreaseItemQty(
                            row,
                            itemId,
                            quantity,
                            packingSize
                          )
                        }
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                      <input
                        className="form-control"
                        type="text"
                        placeholder={quantity}
                        disabled
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {
                      /*row.Availability !== "Unlimited" &&
                    Number(row.Availability) === 0 ? (
                      <div className="form-group--number pointer">
                        ---
                      </div>
                    ) : ( */
                      <div className="form-group--number pointer">
                        <input
                          onClick={() => this.handleAddItemToCart(row)}
                          className="form-control pointer"
                          type="text"
                          placeholder={languageLable(
                            selectedLanguageData,
                            this.state.productAction === PRODUCT_ACTION.EXCHANGE
                              ? "Replace"
                              : this.state.productAction
                          )}
                          readOnly
                        />
                      </div>
                      //)
                    }
                  </React.Fragment>
                )}
              </figure>
            )}
          </div>
        </React.Fragment>
      );
    } else {
      return "---";
    }
  };

  addProductEnquiry = (row) => {
    const { selectedLanguageData } = this.props.app;

    api
      .get(`AddProductEnquiry?ListingId=${row.ProductListingId}`)
      .then((response) => {
        if (response.StatusCode === 200 && response.Result) {
          notification.success({
            message: "Success",
            description: languageLable(
              selectedLanguageData,
              "We will notify you once product is available."
            ),
          });
        }
      });
  };
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(withRouter(InformationDefault));
